<template>
  <div class="text-sm">
     <br>
     <p class="p-3 text-lg font-bold "><span class="typcn typcn-edit"></span> Report Transaksi Pembelian </p>
     <hr class="mt-1 mb-1">
     <br>
     <br>
      <div class="row justify-content-center">
        <div class="col-12 mb-4 bg-white rounded-lg shadow shadow p-3">
            <p class="text-center p-3">Cari data Transaksi Pembelian</p>
            <div class="row justify-content-center">
                <div class="col-6 font-semibold">
                 Kantor : 
                <select class='form-control d-inline' v-model="cabang" style="width:200px;">
                <option value="-1">--ALL--</option>
                <option v-for="(item, index) in cabangs" :key="index+'cabang'" :value="item.id">{{item.cabang}}</option>
                </select>
              </div>
              <div class="col-6 text-center">
                    <div class="sm-form ">
                        <input type="date" id="tanggal1" name="tanggal1" class="form-control form-control-sm" placeholder="tanggal1" v-model="vdata.tanggal1" >
                    </div>
                    S/D
                    <div class="sm-form ">
                        <input type="date" id="tanggal2" name="tanggal2" class="form-control form-control-sm" placeholder="tanggal2" v-model="vdata.tanggal2" >
                    </div>
                    <br>
                    <p class="p-2">Pilih Barang/Produks</p>
                    <v-select :options="produks" label="item_data" v-model="vdata.produks" :reduce="e => e.id"></v-select>
                    <hr>
                    <button type="button" @click="process=1;getData();" class="btn btn-sm btn-dark  "><span class="typcn typcn-zoom-in-outline"></span> Cari</button>
              </div>
            </div>
          <!-- END BUTTON PART -->
        </div>
      </div>
    <!-- TABLE PART -->
    <div v-if="datanya.length>0" class="p-5 shadow rounded-lg">
      <div class="">
        <button type="button" @click="$router.push(`/admin/laporan/laporan`)" class="btn btn-sm btn-dark float-right mt-2"><span class="typcn typcn-document-add"></span> Cetak</button>
        <vue-json-excel :data="datanya">
          <button type="button" class="btn btn-sm btn-dark">
            <span class="typcn typcn-chart-area"></span> Excel 
          </button>
        </vue-json-excel>
      </div>
        <br>
      <Btables :tables="datanya"  :cari="['']" :hide="['']" @selected="ambil" ref="btable" />
      <p class="font-bold text-xl">Total : Rp. {{formatRupiah(masuks)}}</p>
    </div>
    <!-- END TABLE PART -->
  </div>
</template>

<script>
// TOTURIAL 
// ganti source autophp, ganti source component btable, ganti path table sdb
import autophp from "@/plugins/autophp_kasir.js"; // ganti source nya
import Btables from "@/components/Btables.vue"; //component btable nya
let sdb = new autophp();
export default {
  components: {
    Btables,
  },
  layout:'app_shop_admin',
  data() {
    return {
      selected: false,
      vdata: {},
      btn: "tambah",
      datanya:[],
      masuks:0,
      produks:[],
      process:1,
        cabangs:[],
      cabang:'-1'
    };
  },
  methods: {
    getData(){
        let that=this;
       
        
         this.datanya=[]
        let sql;
        if(this.process==1){
          sql=`select tb1.id as no,tb1.tanggal,tb2.nama_produk,tb2.barcode,tb1.nofaktur,tb1.jumlah,tb3.nama as supplier,tb1.keterangan,tb1.harga from app_kasir_stok_masuk tb1 
              left join app_kasir_produk tb2 on tb2.id=tb1.id_produk left join app_kasir_supplier tb3 on tb3.id=tb1.id_supplier     
              WHERE (tb1.tanggal_w >= '${this.vdata.tanggal1}' AND tb1.tanggal_w <= '${this.vdata.tanggal2}') AND tb1.id_produk=${this.vdata.produks}  ${this.cabang!='-1'?`and tb1.cabang='${this.cabang}'`:''}`;
        }else if(this.process==2){
          if(this.vdata.pilihan==1){
            sql=`select tb1.id as no,tb1.tanggal,tb2.nama_produk,tb2.barcode,tb1.nofaktur,tb1.jumlah,tb3.nama as supplier,tb1.keterangan,tb1.harga from app_kasir_stok_masuk tb1 
                left join app_kasir_produk tb2 on tb2.id=tb1.id_produk left join app_kasir_supplier tb3 on tb3.id=tb1.id_supplier  where tb1.id!='-100' ${this.cabang!='-1'?`and tb1.cabang='${this.cabang}'`:''}`
          }
        }
      sdb.collection("app_kasir_stok_masuk",false).doc().select(sql).then(res => {
        this.datanya=res;
        this.masuks=res.reduce((e,n)=>{
          return e+(parseInt(n.harga)*parseInt(n.jumlah))
        },0)
          this.datanya.forEach((e,i)=>{
            e.no=i+1;
            e.tanggal=that.format(e.tanggal)
            e.harga=that.formatRupiah(e.harga,'Rp. ')
          })
          this.datanya.push({harga:this.formatRupiah(this.masuks,'Rp. ')})
        this.$store.state.data.report_masuk=res;
        this.$forceUpdate();
      });
    },
    ambil(data) {
      this.vdata = data;
      this.btn = "update";
      this.$forceUpdate();
    },
     format(date) {
      return this.$datefns.format(date, "DD MMMM YYYY");
    },
    formatRupiah(angka, prefix){ // prefix disini taruh Rp. 
        angka=angka.toString();
        var number_string = angka.replace(/[^,\d]/g, '').toString(),
        split   		= number_string.split(','),
        sisa     		= split[0].length % 3,
        rupiah     		= split[0].substr(0, sisa),
        ribuan     		= split[0].substr(sisa).match(/\d{3}/gi);
        if(ribuan){
        var separator = sisa ? '.' : '';
        rupiah += separator + ribuan.join('.');
        }
        rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
        return prefix == undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
    },
    submit() {
     this.getData();
    },
    getCabang(){
      sdb.collection("app_kasir_toko_cabang",false).doc().select(`select * from app_kasir_toko_cabang`).then(res => {
        this.cabangs=res;
        this.$forceUpdate();
      });
    },
  },
  async mounted() {
    this.getCabang()
       sdb.collection("app_kasir_produk",false).doc().select(`select * from app_kasir_produk`).then(res => {
            this.produks=res;
            this.produks.map(function (x){ // taruh setelah produks di initial
                    return x.item_data = x.barcode + ' / ' + x.nama_produk;
            });
            this.$forceUpdate();
        });
  },
};
</script>